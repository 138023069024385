import React from 'react'
import './css/bootstrap.min.css'
import './style.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createRoot } from 'react-dom/client'

createRoot(document.getElementById('root')).render(<App />)

reportWebVitals()
